exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-case-archive-tsx": () => import("./../../../src/templates/case-archive.tsx" /* webpackChunkName: "component---src-templates-case-archive-tsx" */),
  "component---src-templates-case-tsx": () => import("./../../../src/templates/case.tsx" /* webpackChunkName: "component---src-templates-case-tsx" */),
  "component---src-templates-gallery-tsx": () => import("./../../../src/templates/gallery.tsx" /* webpackChunkName: "component---src-templates-gallery-tsx" */),
  "component---src-templates-news-archive-tsx": () => import("./../../../src/templates/news-archive.tsx" /* webpackChunkName: "component---src-templates-news-archive-tsx" */),
  "component---src-templates-news-item-tsx": () => import("./../../../src/templates/news-item.tsx" /* webpackChunkName: "component---src-templates-news-item-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}


import type { PropertyValue } from "@stitches/react"
import { createStitches } from "@stitches/react"
import { removeHashFromString } from "@utils/removeHashFromString"
import { DARK_CLASS, GOLD_CLASS } from "../constants"

const colors = {
    effieBackground: "#221E1F",
    effieGold: "#B4975A",
    effieGoldBackground: "#BE9F56",
    G500: "#00664C",
    G400: "#008765",
    G300: "#36B37E",
    G200: "#36B389",
    G100: "#79F2CA",
    G75: "#ABF5D6",
    G50: "#E3FCEF",
    B500: "#0741A6",
    B400: "#004ECC",
    B300: "#297AFF",
    B200: "#2684FF",
    B100: "#4C91FF",
    B75: "#B3D0FF",
    B50: "#DEEBFF",
    R500: "#BF2600",
    R400: "#DE350B",
    R300: "#FF5630",
    R200: "#FF7452",
    R100: "#FF8F73",
    R75: "#FFBDAD",
    R50: "#FFEBE6",
    Y500: "#FF8B00",
    Y400: "#FF991F",
    Y300: "#FFAB00",
    Y200: "#FFC400",
    Y100: "#FFE380",
    Y75: "#FFF0B3",
    Y50: "#FFFAE6",
    N1000: "#000000",
    N900: "#091E42",
    N800: "#172B4D",
    N700: "#253858",
    N600: "#344563",
    N500: "#42526E",
    N400: "#505F79",
    N300: "#5E6C84",
    N200: "#6B778C",
    N100: "#7A869A",
    N90: "#8993A4",
    N80: "#97A0AF",
    N70: "#A5ADBA",
    N60: "#B3BAC5",
    N50: "#C1C7D0",
    N40: "#DFE1E6",
    N30: "#EBECF0",
    N20: "#F4F5F7",
    N10: "#FAFBFC",
    N0: "#FFFFFF"
}

export const {
    styled,
    getCssText,
    theme,
    globalCss,
    createTheme
} = createStitches({
    theme: {
        colors: {
            ...colors,
            navText: colors.effieBackground,
            text: colors.effieBackground,
            background: colors.N0,
            textInverse: colors.N0,
            backgroundInverse: colors.effieBackground,
            border: "rgba(0, 0, 0, 0.3)",
            fadedText: "rgba(0, 0, 0, 0.65)",
            arrowRight: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23${removeHashFromString(colors.effieBackground)}' viewBox='0 0 16 16'%3E%3Cpath d='M7.89,0,6,1.87,10.69,6.5H0v3H10.69L6,14.13,7.89,16,16,8Z'/%3E%3C/svg%3E")`,
            chevron: `url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.707 3.293a1 1 0 0 1 0 1.414L8.414 12l7.293 7.293a1 1 0 0 1-1.414 1.414l-8-8a1 1 0 0 1 0-1.414l8-8a1 1 0 0 1 1.414 0Z' fill='%23${removeHashFromString(colors.N0)}'/%3E%3C/svg%3E%0A")`,
            chevronInverse: `url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.707 3.293a1 1 0 0 1 0 1.414L8.414 12l7.293 7.293a1 1 0 0 1-1.414 1.414l-8-8a1 1 0 0 1 0-1.414l8-8a1 1 0 0 1 1.414 0Z' fill='%23${removeHashFromString(colors.effieBackground)}'/%3E%3C/svg%3E%0A")`,
            triangle: `url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m4.415 4.981 2.648-3.516C7.375 1.05 7.12.4 6.647.4H1.353C1.136.4.939.547.85.777a.725.725 0 0 0 .087.688L3.584 4.98c.105.14.256.22.416.22.16 0 .31-.08.415-.22Z' fill='%23${removeHashFromString(colors.N0)}'/%3E%3C/svg%3E")`,
            triangleWhite: `url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m4.415 4.981 2.648-3.516C7.375 1.05 7.12.4 6.647.4H1.353C1.136.4.939.547.85.777a.725.725 0 0 0 .087.688L3.584 4.98c.105.14.256.22.416.22.16 0 .31-.08.415-.22Z' fill='%23${removeHashFromString(colors.N0)}'/%3E%3C/svg%3E")`
        },
        fonts: {
            body: "ITC Avant Garde Gothic Std",
            text: "Avenir Next"
        },
        fontWeights: {
            regular: 400,
            medium: 500,
            semiBold: 500,
            bold: 700
        },
        fontSizes: {
            h1: "60px",
            h2: "56px",
            h3: "40px",
            h4: "24px",
            h5: "16px",
            h1Mobile: "40px",
            h2Mobile: "40px",
            h3Mobile: "30px",
            h4Mobile: "20px",
            h5Mobile: "14px",
            label: "12px",
            textBig: "20px",
            text: "16px",
            textSmall: "16px",
            textMobile: "16px",
            textNormal: "14px"
        }
    },
    media: {
        smDown: "(max-width: 640px)",
        mdDown: "(max-width: 768px)",
        lgDown: "(max-width: 1024px)",
        xlDown: "(max-width: 1280px)",
        xxlDown: "(max-width: 1536px)",
        smUp: "(min-width: 640px)",
        mdUp: "(min-width: 768px)",
        lgUp: "(min-width: 1024px)",
        xlUp: "(min-width: 1280px)",
        xxlUp: "(min-width: 1536px)"
    },
    utils: {
        m: (value: PropertyValue<"margin">) => ({
            margin: value
        }),
        mt: (value: PropertyValue<"margin">) => ({
            marginTop: value
        }),
        mr: (value: PropertyValue<"margin">) => ({
            marginRight: value
        }),
        mb: (value: PropertyValue<"margin">) => ({
            marginBottom: value
        }),
        ml: (value: PropertyValue<"margin">) => ({
            marginLeft: value
        }),
        mx: (value: PropertyValue<"margin">) => ({
            marginLeft: value,
            marginRight: value
        }),
        my: (value: PropertyValue<"margin">) => ({
            marginTop: value,
            marginBottom: value
        }),
        p: (value: PropertyValue<"padding">) => ({
            padding: value
        }),
        pt: (value: PropertyValue<"padding">) => ({
            paddingTop: value
        }),
        pr: (value: PropertyValue<"padding">) => ({
            paddingRight: value
        }),
        pb: (value: PropertyValue<"padding">) => ({
            paddingBottom: value
        }),
        pl: (value: PropertyValue<"padding">) => ({
            paddingLeft: value
        }),
        px: (value: PropertyValue<"padding">) => ({
            paddingLeft: value,
            paddingRight: value
        }),
        py: (value: PropertyValue<"padding">) => ({
            paddingTop: value,
            paddingBottom: value
        }),
        // Always use this when setting a `fontSize`, except if you use a theme defined fontSize.
        fontSizePxToRem: (value: number) => {
            return ({
                fontSize: `${value / 16}rem`
            })
        }
    }
})

const darkThemeColors = {
    text: colors.N0,
    navText: colors.N0,
    background: colors.effieBackground,
    textInverse: colors.effieBackground,
    backgroundInverse: colors.N0,
    border: "rgba(255, 255, 255, 0.3)",
    fadedText: "rgba(255, 255, 255, 0.65)",
    arrowRight: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23${removeHashFromString(colors.N0)}' viewBox='0 0 16 16'%3E%3Cpath d='M7.89,0,6,1.87,10.69,6.5H0v3H10.69L6,14.13,7.89,16,16,8Z'/%3E%3C/svg%3E")`,
    chevron: `url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.707 3.293a1 1 0 0 1 0 1.414L8.414 12l7.293 7.293a1 1 0 0 1-1.414 1.414l-8-8a1 1 0 0 1 0-1.414l8-8a1 1 0 0 1 1.414 0Z' fill='%23${removeHashFromString(colors.effieBackground)}'/%3E%3C/svg%3E%0A")`,
    chevronInverse: `url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.707 3.293a1 1 0 0 1 0 1.414L8.414 12l7.293 7.293a1 1 0 0 1-1.414 1.414l-8-8a1 1 0 0 1 0-1.414l8-8a1 1 0 0 1 1.414 0Z' fill='%23${removeHashFromString(colors.N0)}'/%3E%3C/svg%3E%0A")`,
    triangle: `url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m4.415 4.981 2.648-3.516C7.375 1.05 7.12.4 6.647.4H1.353C1.136.4.939.547.85.777a.725.725 0 0 0 .087.688L3.584 4.98c.105.14.256.22.416.22.16 0 .31-.08.415-.22Z' fill='%23${removeHashFromString(colors.effieBackground)}'/%3E%3C/svg%3E")`
}

export const darkTheme = createTheme(DARK_CLASS, {
    colors: darkThemeColors
})

export const goldTheme = createTheme(GOLD_CLASS, {
    colors: {
        navText: colors.N0
    }
})

import { CSS } from "@stitches/react"
import { globalCss, theme } from "@theme/stitches.config"
import { normalize } from "stitches-normalize-css"

import "@fonts/ITC/400.css"
import "@fonts/ITC/500.css"
import "@fonts/ITC/700.css"

import "@fonts/avenir-next/400.css"
import "@fonts/avenir-next/500.css"

const customGlobalStyles: Record<string, CSS> = {
    "*, *::before, *::after": {
        boxSizing: "border-box"
    },
    html: {
        textSizeAdjust: "100%",
        fontFamily: theme.fonts.body
    },
    body: {
        fontFamily: theme.fonts.body,
        fontWeight: theme.fontWeights.regular,
        backgroundColor: theme.colors.background,
        fontSize: "1rem",
        lineHeight: 1,
        margin: 0,
        iframe: {
            background: "#fff",
            border: 0,
            width: "100%",
            height: 1370,
            "@lgDown": {
                height: 2700
            },
            "@mdDown": {
                display: "flex",
                height: 3000
            },
            "& .RegFormContainer": {
                "& .FormContainer": {
                    margin: "0px!important"
                }
            }
        }
    },
    "*": {
        transition: "background 0.3s ease, color 0.2s ease"
    },
    "h1, .h1": {
        lineHeight: 1.15,
        fontSize: theme.fontSizes.h1Mobile,
        "@mdUp": {
            fontSize: theme.fontSizes.h1
        }
    },
    "h2, .h2": {
        lineHeight: 1.15,
        fontSize: theme.fontSizes.h2Mobile,
        "@mdUp": {
            fontSize: theme.fontSizes.h2
        }
    },
    "h3, .h3": {
        lineHeight: 1.1,
        fontSize: theme.fontSizes.h3Mobile,
        "@mdUp": {
            fontSize: theme.fontSizes.h3
        }
    },
    "h4, .h4": {
        lineHeight: 1.25,
        fontSize: theme.fontSizes.h4Mobile,
        "@mdUp": {
            fontSize: theme.fontSizes.h4
        }
    },
    "h5, .h5": {
        lineHeight: 1.25,
        letterSpacing: 2,
        fontSize: theme.fontSizes.h5Mobile,
        "@mdUp": {
            fontSize: theme.fontSizes.h5
        }
    },
    "h1,h2,h3,h4,h5,.h1,.h2,.h3,.h4,.h5": {
        wordBreak: "break-word",
        color: theme.colors.text,
        fontFamily: theme.fonts.heading,
        margin: 0,
        marginBottom: "1.5rem"
    },
    "h1,h2,h3,.h1,.h2,.h3": {
        fontWeight: theme.fontWeights.bold
    },
    "h4,h5,.h4,.h5": {
        fontWeight: theme.fontWeights.semiBold
    },
    "p, a, main li, div[role='dialog']": {
        lineHeight: 1.63,
        margin: 0,
        fontWeight: theme.fontWeights.regular,
        color: theme.colors.text,
        fontSize: theme.fontSizes.textMobile,
        "@mdUp": {
            fontSize: theme.fontSizes.text
        }
    },
    p: {
        fontFamily: theme.fonts.text
    },
    a: {
        textDecoration: "none"
    },
    ".gatsby-focus-wrapper": {
        overflow: "hidden"
    },
    ".overline": {
        fontFamily: theme.fonts.body,
        color: theme.colors.effieGold,
        textTransform: "uppercase",
        lineHeight: 2,
        letterSpacing: 2,
        fontWeight: theme.fontWeights.medium,
        marginBottom: "0.75rem"
    },
    ".btn": {
        fontFamily: theme.fonts.body,
        fontWeight: theme.fontWeights.semiBold,
        mt: 20,
        minHeight: 48,
        appearance: "none",
        cursor: "pointer",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 6,
        padding: "8px 24px",
        fontSizePxToRem: 14,
        border: "1px solid transparent",
        textDecoration: "none",
        lineHeight: 1.2,
        transition: "all 200ms ease",
        outline: "none",
        textTransform: "uppercase",
        letterSpacing: 1
    },
    "p .btn": {
        marginTop: "1.5rem"
    },
    ".btn.btn-filled-gold": {
        backgroundColor: theme.colors.effieGold,
        border: `1px solid ${theme.colors.effieGold}`,
        color: theme.colors.N0,
        "&:hover": {
            backgroundColor: theme.colors.N0,
            color: theme.colors.effieGold
        }
    },
    ".btn.btn-outlined-gold": {
        border: `1px solid ${theme.colors.effieGold}`,
        color: theme.colors.effieGold,
        background: "transparent",
        "&:hover": {
            background: theme.colors.effieGold,
            color: theme.colors.N0
        }
    },
    ".btn.btn-filled-black": {
        backgroundColor: theme.colors.effieBackground,
        border: `1px solid ${theme.colors.effieBackground}`,
        color: theme.colors.N0,
        "&:hover": {
            backgroundColor: theme.colors.N0,
            color: theme.colors.effieBackground
        }
    },
    ".btn.btn-outlined-black": {
        border: `1px solid ${theme.colors.effieBackground}`,
        color: theme.colors.effieGold,
        background: "none",
        "&:hover": {
            background: theme.colors.effieBackground,
            color: theme.colors.N0
        }
    },
    ".btn.btn-filled-white": {
        border: `1px solid ${theme.colors.N0}`,
        backgroundColor: theme.colors.N0,
        color: theme.colors.effieBackground,
        boxShadow: "0px 3px 9px rgba(57, 20, 0, 0.08)",
        "&:hover": {
            backgroundColor: theme.colors.N30
        }
    },
    "a.btn.btn-plain": {
        marginTop: 20,
        minHeight: 48,
        appearance: "none",
        cursor: "pointer",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 6,
        padding: " 8px 24px",
        textDecoration: "none",
        lineHeight: 1.2,
        transition: "all 200ms ease 0s",
        outline: "none",
        letterSpacing: 1
    },
    ".btn.btn-plain": {
        backgroundColor: "transparent",
        color: theme.colors.N0,
        textTransform: "none",
        letterSpacing: "normal",
        fontSize: theme.fontSizes.text,
        fontWeight: theme.fontWeights.semiBold,
        border: "1px solid transparent",
        "@mdDown": {
            display: "block",
            mx: 0,
            px: 0
        },
        "&:hover": {
            textDecoration: "none",
            borderColor: "inherit"
        }
    },
    table: {
        borderCollapse: "collapse",
        my: "2rem",
        "th, td": {
            border: `1px solid ${theme.colors.text}`,
            padding: 8
        },
        th: {
            fontWeight: theme.fontWeights.semiBold
        }
    }
}

export const globalStyles = globalCss(...normalize, customGlobalStyles)
